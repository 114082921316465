<template>
  <div class="page">
    <div class="main">
      <div class="title">卡片列表</div>
      <el-select
        v-model="parent_id"
        placeholder="卡片所属老师"
        size="mini"
        clearable
        style="margin-top: 20px; margin-bottom: 20px"
        @change="getCardList"
      >
        <el-option
          v-for="item in ExpertList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 60px"
        @click="download"
        >下载</el-button
      >

      <el-table :data="cardList" border style="width: 100%">
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="card_num" label="卡号"></el-table-column>
        <el-table-column prop="create_time" label="时间"></el-table-column>
        <el-table-column prop="expert_id" label="所属导师">
          <template scope="scope">
            <div v-for="(item, key) in ExpertList" :key="key">
              <div v-if="item.id == scope.row.parent_id">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="edit" label="编辑" width="400">
          <template slot-scope="scope">
            <i
              class="el-icon-edit"
              @click="handleEdit(scope.row.id)"
              style="margin-left: 20px"
              >编辑</i
            >
            <i
              class="el-icon-edit"
              @click="changeRule(scope.row.id)"
              style="margin-left: 20px"
              >冻结</i
            >
            <i
              class="el-icon-close"
              @click="handleDel(scope.row.id)"
              style="margin-left: 20px"
              >删除</i
            >
          </template>
        </el-table-column>
        <el-table-column prop="brief" label="备注">
          <template slot-scope="scope">
            <i
              class="el-icon-edit"
              @click="edit(scope.row.id, scope.row.brief)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="true"
          :page-size="pageSize"
          :total="total"
          class="page-box"
          layout="prev, pager, next"
          @current-change="pageChange"
        />
      </div>
    </div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.centerDialogVisible"
      :close-on-click-modal="false"
      width="65%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="180px"
        :inline="true"
        style="margin-top: 40px"
      >
        <el-form-item label="卡号">
          <el-input v-model="formData.card_num" size="mini" disabled></el-input>
        </el-form-item>
        <br />
        <el-form-item label="密码">
          <el-input v-model="formData.password" size="mini"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="备注" :visible.sync="dialog.briefDialog" width="500px">
      <el-input v-model="msg"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.briefDialog = false">取 消</el-button>
        <el-button type="primary" @click="editBrief">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//time: 2021/1/27
export default {
  name: "CardList",
  data() {
    return {
      parent_id: null,
      ExpertInfo: [],
      ExpertList: [],
      cardList: [],
      dialog: {
        centerDialogVisible: false,
        briefDialog: false,
      },
      formData: {
        name: "",
      },
      msg: "",
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.getList();
    this.getCardList();
  },
  methods: {
    getList() {
      const data = {
        parent_id: this.ExpertInfo.id,
      };
      this.$fecth.post("expert/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.ExpertList = data.lists;
          this.ExpertList.push({
            name: this.ExpertInfo.name,
            id: this.ExpertInfo.id,
          });
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleEdit(id) {
      this.formData.id = id;
      const data = {
        id: id,
      };
      this.$fecth.post("vip/getDataById", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.formData = data;
          this.dialog.centerDialogVisible = true;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    getCardList() {
      if (!this.parent_id) {
        this.parent_id = this.ExpertInfo.id;
      }
      const data = {
        parent_id: this.parent_id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$fecth.post("vip/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.cardList = data.lists;
          this.total = data.total;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    handleDel(id) {
      const data = {
        id: id,
      };
      this.$fecth.post("vip/delete", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.getCardList();
          this.showTips(1, "删除成功");
        } else {
          this.showTips(0, msg);
        }
      });
    },
    add() {
      const data = {
        id: this.formData.id,
        password: this.formData.password,
      };
      this.$fecth.post("vip/edit", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.dialog.centerDialogVisible = false;
          this.showTips(1, msg);
          this.getList();
        } else {
          this.showTips(0, msg);
        }
      });
    },
    changeRule(id) {
      let data = {
        id: id,
        status: 0,
      };
      this.$fecth.post("vip/editStatus", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, "冻结成功");
        } else {
          this.showTips(0, msg);
        }
      });
    },
    edit(id, brief) {
      this.id = id;
      this.msg = brief;
      this.dialog.briefDialog = true;
    },
    editBrief() {
      let data = {
        id: this.id,
        brief: this.msg,
      };
      this.$fecth.post("vip/editBrief", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.dialog.briefDialog = false;
          this.getCardList();
          this.showTips(1, "修改成功");
        } else {
          this.showTips(0, msg);
        }
      });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getCardList();
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    download() {
      const parent_id = this.parent_id;
      const url =
        // "https://www.zhicaigaobao.com/index.php/expert/user/downloadUser?parent_id=" +
        "http://x.bkgaoshou.com/index.php/expert/user/downloadUser?parent_id=" +
        parent_id;
      window.location.href = url;
    },
  },
};
</script>
